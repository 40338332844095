import React from 'react';
import Experience from "../images/img/Experience.png";
import Atolbook from "../images/img/atolbook.png";
import PTS from "../images/img/pts.png";
import Secured from "../images/img/Secured.png";
function Whytrust() {
  return (
    <>
      <div className='container whybook'>
                <div className='row'>
                    <div className='col-12 sheading2'><h2>Why Trust Fare Scanner UK?</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                                <img src={Experience} alt="Experience" />
                            </div>
                            <p>10 Years of Experience</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                                <img src={Atolbook} alt="atol book" />
                            </div>
                            <p>ATOL Protected</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                                <img src={PTS} alt="PTS" />
                            </div>
                            <p>Secured with PTS</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                                <img src={Secured} alt="Secured" />
                            </div>
                            <p>Secured Booking & Payments</p>
                        </div>
                    </div>

                </div>
            </div>
    </>
  )
}

export default Whytrust
