import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FromAirport from './FromAirport';
import ToAirport from './ToAirport';
export default function CarSearchEng() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [contactNumber, setContactNumber] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input consists of exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(inputValue);

    setContactNumber(inputValue);
    setIsValid(isValidNumber);
  };
  return (
    <>
      <div className="col-md-12">
      <form action="https://www.farescanner.uk/connecting.php" method="post" autocomplete="off">
          <div className="row">
            <input type="hidden" name="xx_Sb" value="Car Search" />
            <div className="col-md-3">
              <div className="form-group">
                <label>Pick up from</label>
                <FromAirport />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Drop off at</label>
                <ToAirport />

              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Pick up date</label>
                <DatePicker className="form-control" name="DepartDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={new Date()}

                  showDisabledMonthNavigation
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat='dd/MM/yyyy'
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Drop off date</label>
                <DatePicker className="form-control" name="ReturnDate"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat='dd/MM/yyyy'
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Your Name </label>
                <input type="text" name="fname" className="form-control" required />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group">
                <label>Contact Number</label>
                <span className="input-group-text">+44</span>
                <input
                  type="number"
                  name="cNumber"
                  className={`form-control ${isValid ? '' : 'is-invalid'}`}
                  maxLength={10}
                  minLength={10}
                  value={contactNumber}
                  onChange={handleContactNumberChange}
                  required
                />
                {!isValid && (
                  <div className="invalid-feedback">
                   Please enter a valid number
                  </div>
                )}
              </div>
              <div className="offertext">
           Please enter a valid number to get upto  £100 discount for this search.
          </div>
            </div>
            <div className="col-md-2 w30">
              <div className="form-group">
                <label>Pick up time</label>
               <select name="Pickuptime" id="Pickuptime" className="form-control">
                  <option value="00:00"> 00:00 </option>
                  <option value="01:00"> 01:00 </option>
                  <option value="02:00"> 02:00 </option>
                  <option value="03:00"> 03:00 </option>
                  <option value="04:00"> 04:00 </option>
                  <option value="05:00"> 05:00 </option>
                  <option value="06:00"> 06:00 </option>
                  <option value="07:00"> 07:00 </option>
                  <option value="08:00"> 08:00 </option>
                  <option value="09:00"> 09:00 </option>
                  <option value="10:00"> 10:00 </option>
                  <option value="11:00"> 11:00 </option>
                  <option value="12:00"> 12:00 </option>
                  <option value="13:00"> 13:00 </option>
                  <option value="14:00"> 14:00 </option>
                  <option value="15:00"> 15:00 </option>
                  <option value="16:00"> 16:00 </option>
                  <option value="17:00"> 17:00 </option>
                  <option value="18:00"> 18:00 </option>
                  <option value="19:00"> 19:00 </option>
                  <option value="20:00"> 20:00 </option>
                  <option value="21:00"> 21:00 </option>
                  <option value="22:00"> 22:00 </option>
                  <option value="23:00"> 23:00 </option>
                </select>
              </div>
            </div>
            <div className="col-md-2 w30">
              <div className="form-group">
                <label>Drop off time</label>
                <select name="Droptime" id="Droptime" className="form-control">
                  <option value="00:00"> 00:00 </option>
                  <option value="01:00"> 01:00 </option>
                  <option value="02:00"> 02:00 </option>
                  <option value="03:00"> 03:00 </option>
                  <option value="04:00"> 04:00 </option>
                  <option value="05:00"> 05:00 </option>
                  <option value="06:00"> 06:00 </option>
                  <option value="07:00"> 07:00 </option>
                  <option value="08:00"> 08:00 </option>
                  <option value="09:00"> 09:00 </option>
                  <option value="10:00"> 10:00 </option>
                  <option value="11:00"> 11:00 </option>
                  <option value="12:00"> 12:00 </option>
                  <option value="13:00"> 13:00 </option>
                  <option value="14:00"> 14:00 </option>
                  <option value="15:00"> 15:00 </option>
                  <option value="16:00"> 16:00 </option>
                  <option value="17:00"> 17:00 </option>
                  <option value="18:00"> 18:00 </option>
                  <option value="19:00"> 19:00 </option>
                  <option value="20:00"> 20:00 </option>
                  <option value="21:00"> 21:00 </option>
                  <option value="22:00"> 22:00 </option>
                  <option value="23:00"> 23:00 </option>
                </select>
              </div>
            </div>
           <div className="col-md-2">
              <button type='submit' name="SearchCar" className="btn btn-primary flightBTN"><i className="fa fa-car"></i> Search Car</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}


