import React, { Component } from 'react';
import { MoviesData, renderMovieTitle } from './FrAirportList';
import Autocomplete from 'react-autocomplete';

class FromAirport extends Component {
  state = {
    val: '',
    isValid: true,
  };

  validateInput = (value) => {
    // Example validation logic, you can customize this based on your requirements
    return value.trim() !== ''; // Check if the value is not empty
  };

  render() {
    const { val, isValid } = this.state;

    return (
      <div className="autocomplete-wrapper">
        <Autocomplete
          value={val}
          inputProps={{ name: 'Source' }}
          items={MoviesData()}
          getItemValue={(item) => item.name}
          shouldItemRender={renderMovieTitle}
          renderMenu={(item) => (
            <div className="dropdown form-control">
              {item}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
              {item.name}
            </div>
          )}
          onChange={(event, value) => {
            const isValid = this.validateInput(value);
            this.setState({ val: value, isValid });
          }}
          onSelect={(value) => {
            const isValid = this.validateInput(value);
            this.setState({ val: value, isValid });
          }}
        />
        {!isValid && <div className="error-message">Please enter a valid value.</div>}
      </div>
    );
  }
}

export default FromAirport;
