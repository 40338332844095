import React from 'react';
import CallNo from './CallNo';
import 'bootstrap/dist/css/bootstrap.min.css';
export default function Thanks() {
    return (
        <>
     <section className="thankspage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
            <i className="fa fa-check-circle"></i> 
              <h2>Thank You</h2>
              <p className="thp">Thanks for submitting your flight query.<br />Please be patient as one of our Travel Expert will get in touch with you very soon.<br />If you need any urgent help please do call us now and we are happy to help you.
</p>            
<p  className="thp"><strong>Call -</strong> <CallNo /><br /> 
<strong>Email-</strong> info@farescanner.uk</p> 
            </div>
          </div>
        </div>
      </section>
       
        </>
    )
}
