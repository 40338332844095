import React from 'react';
import Africa from "../images/destination/Africa.jpg";
import Asia from "../images/destination/Asia.jpg";
import CostaRica from "../images/destination/CostaRica.jpg";
import Europe from "../images/destination/Europe.jpg";
import NewJersey from "../images/destination/NewJersey.jpg";
import NewYorkCity from "../images/destination/NewYorkCity.jpg";

function TopDestinations() {
    return (
        <>
            <div className='container mb-5 topDestinations'>
                <div className='row'>
                    <div className='col-md-12 heading2'><h2>We Provide Top Destinations</h2></div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={Africa} alt="Africa" />
                            <span>&pound; 299</span>
                        </div>
                        <h2>Africa</h2>
                    </div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={Asia} alt="Asia" />
                            <span>&pound; 199</span>
                        </div>
                        <h2>Asia</h2>
                    </div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={CostaRica} alt="Costa Rica" />
                            <span>&pound; 399</span>
                        </div>
                        <h2>Costa Rica</h2>
                    </div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={Europe} alt="Europe" />
                            <span>&pound; 499</span>
                        </div>
                        <h2>Europe</h2>
                    </div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={NewJersey} alt="New Jersey" />
                            <span>&pound; 399</span>
                        </div>
                        <h2>New Jersey</h2>
                    </div>
                    <div className='col-md-2 topName'>
                        <div className='topDes'>
                            <img src={NewYorkCity} alt="New York City" />
                            <span>&pound; 299</span>
                        </div>
                        <h2>New York City</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopDestinations