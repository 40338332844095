import React from 'react';
function CallNo() {
  return (
    <>
      <a href='tel:0208 095 4111'>0208 095 4111</a>
    </>
  )
}

export default CallNo
