import React, { useState } from 'react';
import { Link } from "react-router-dom";
 

function NavBar() {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <>
    <section className='nbb'>
      <div className='container'>
                    <div className='row'>
                        <div className='col-md-10'>
      <nav className={`navbar ${isActive ? 'active' : ''}`}>

        <ul className={`nav-menu ${isActive ? 'active' : ''}`}>

          <li className="nav-item">
            <Link to='/' className="nav-link"><i className='fa fa-home'></i> Home</Link>
          </li>
          <li className="nav-item">
            <Link to='/Destination' className="nav-link"><i className='fa fa-plane'></i> Destination</Link>
          </li>
         
          <li className="nav-item">
            <Link to='/Business-and-First-Class' className="nav-link"><i className='fa fa-suitcase'></i> Business & First Class</Link>
          </li>
          <li className="nav-item">
            <Link to='/Airlines' className="nav-link"><i className='fa fa-plane'></i> Airlines</Link>
          </li>
          <li className="nav-item">
            <Link to='/Holidays' className="nav-link"><i className='fa fa-glass'></i> Holidays</Link>
          </li>
          <li className="nav-item">
            <Link to='/Cruises' className="nav-link"><i className='fa fa-ship'></i> Cruises</Link>
          </li>
        
          <li className="nav-item">
            <Link to='/Car-Hire' className="nav-link"><i className='fa fa-car'></i> Car Hire</Link>
          </li>
        </ul>
        <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

      </nav>
      </div>
      <div className='col-md-2 mybooking'>
        <ul>
          <li> <Link to='/Holidays' className="nav-link"><i className='fa fa-user'></i> My Booking</Link></li>
        </ul>
     
      </div>
      </div>
      </div>
      </section>
    </>
  );
}

export default NavBar;
