import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CallNo from '../Include/CallNo';

function CruisesList() {
  const [cruises, setCruises] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCruises();
  }, []);

  const fetchCruises = async () => {
    try {
      const response = await fetch('https://www.farescanner.uk/admin/CruisesAPI');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // Filter out duplicate destinations
      const uniqueDestinations = data.filter((cruise, index, self) =>
        index === self.findIndex((t) => (
          t.destinations === cruise.destinations
        ))
      );
      setCruises(uniqueDestinations);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cruises:', error);
      setLoading(false);
    }
  };

  return (
    <>
    <div className='container p0'>
      <div className='row'>
        <div className='sheading2 crheading'><h2>Featured Cruises</h2></div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          cruises.map(cruise => (
            <div key={cruise.id} className='col-md-4'>
              <div className='crlist'>
                <img src={cruise.destinationsImg} alt={cruise.title} />
                <h2>{cruise.destinations} Cruises</h2>
                <h3>Starting from <span>£{cruise.price}</span></h3>
                <h4>
                  <Link to={`/Cruise/${encodeURIComponent(cruise.destinations)}`}>View Details</Link>
                  <CallNo />
                </h4>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    </>
  );
}

export default CruisesList;
