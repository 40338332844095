import { useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';

export default function ScrollTop() {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);

        // Cleanup function to avoid memory leaks
        return () => {
            // You can add cleanup code here if needed
        };
    }, [pathname]);

    return null;
}
