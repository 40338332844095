import React, { useState } from 'react';
import { Link } from "react-router-dom";
import CallNo from './CallNo';
import FareLogo from "../images/img/farescanner.png";
import PTSLogo from "../images/img/pts-logo.png";
import CallUs from "../images/img/callUs.png";
import NavBar from './NavBar';
function Header() {
 
    return (
        <>
            <header>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='headerLogo'>
                                <Link to='/'>
                                    <img src={FareLogo} alt="Farescanner.uk" className='logo' />
                                </Link>
                                 
                            </div>
                            </div>
                            <div className='col-md-5'>
                            <div className='ptsLogo'><img src={PTSLogo} alt="Farescanner.uk" /></div>
                        </div>

                        <div className='col-md-3 headerNo'>
                            <div className='hno'><i className='fa fa-phone'></i><CallNo />  <span>We are open 24 hours a day / 7 days a week</span></div>
                          
                        </div>
                    </div>
                </div>

            </header>
             
            <NavBar />
        </>
    );
}

export default Header;
