import React from 'react';
import CallNo from '../Include/CallNo';
function AboutUs() {
    return (
        <>
            <section className='pagecontent'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-md-12'>
                            <h1>About Us </h1>
                             <p>In today’s fast-paced and stressful life, everyone needs a break to recharge themselves and feel rejuvenated. Traveling is a therapy that calms our senses, rekindles our relationship with nature, and boosts our immunity. Planning a holiday trip systematically and strategically saves time, money, and tons of stress. The Farescanner.uk helps to make your dream trips possible no matter where you want to go. Whether planning an adventurous safari with your friends, a beach holiday with family, or want to enjoy a solo adventurous trek or planning a business meeting on a luxurious getaway, we customize your specific needs and make your travel planning process a stress-free breeze. </p>
                           
                            <p>Based in the United Kingdom and registered with Companies House with <strong>registration number 04501991.</strong> Carefully grown in size, reputation, and abilities the products offered by the company are <strong>ATOL-protected</strong> ensuring complete safety and protection of all financial transactions. the company provides an opportunity to experience and explore beautiful cities, meet people and celebrate their rich cultural heritage, discover the lavishness of natural beauty, explore thrilling wildlife, and relish scrumptious food.   </p>
                          
                            <p>With a team of experienced travel experts, we help you turn your dream holiday into reality. Our fast and user-friendly navigation allows you to explore and avail the best deals on hotel accommodations, flights, holiday packages, and car rentals in one place. We offeran array of assorted options catering to different budgets and preferences ensuring the best discounted deals. Our flexible search feature allows you to compare prices of hotels, flights, and holiday packages helping you to find the mostcost-effective options by booking everything together. </p>
                        
                            <p>Our easy navigation, assured financial security, intuitive booking process, advanced search filters, transparent price policy without hidden fees, fast and efficient customer support, exclusive discounts, and extensive industry experience with personalized service ensure our guests a memorable travel experience that will remain with them throughout their lives. </p>
                           
                            <p>Call us now at <strong><CallNo /></strong> for a refreshing and seamless booking experience to enjoy a peaceful travel experience without compromising on quality. </p>
                            
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default AboutUs
