import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import bannerSlider from "../images/img/bannerSlider.jpg";
import bannerSlider2 from "../images/img/bannerSlider2.jpg";
import SearchEng from '../Include/SearchEng';
import TopDestinations from '../Destinations/TopDestinations';
import MostPopular from '../Destinations/MostPopular';
import FlightsAround from '../Destinations/FlightsAround';
import WhyBookAirline from '../Airline/WhyBookAirline';
import Experience from "../images/img/Experience.png";
import Atolbook from "../images/img/atolbook.png";
import PTS from "../images/img/pts.png";
import Secured from "../images/img/Secured.png";
function Home() {
    return (
        <>
            <div className='container-fluid p0'>
                <div className='row'>
                    <div className='col-md-12 homeSearch'>
                        <Carousel>
                            <Carousel.Item>
                                <img src={bannerSlider} alt="bannerSlider" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={bannerSlider2} alt="bannerSlider" />
                            </Carousel.Item>

                        </Carousel>
                        <div className='container myflight'>
                            <SearchEng />

                        </div>
                    </div>
                </div>
            </div>
            <WhyBookAirline />

            <MostPopular />

            <div className='container whybook'>
                <div className='row'>
                    <div className='col-12 sheading2'><h2>Why Trust Fare Scanner UK?</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                            <img src={Experience} alt="Experience" />
                            </div>
                            <p>10 Years of Experience</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                            <img src={Atolbook} alt="atol book" />
                            </div>
                           <p>ATOL Protected</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                            <img src={PTS} alt="PTS" />
                            </div>
                            <p>Secured with PTS</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='whybookbox'>
                            <div className='whybookIcon'>
                            <img src={Secured} alt="Secured" />
                            </div>
                            <p>Secured Booking & Payments</p>
                        </div>
                    </div>

                </div>
            </div>
           
            <FlightsAround />
        </>
    )
}

export default Home
