import React from 'react';
import CallNo from '../Include/CallNo';
function Disclaimer() {
    return (
        <>
            <section className='pagecontent'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            {/* <h1>Disclaimer</h1>
                            <h2>WE COMPLETELY RESPECT YOUR PRIVACY AND PROTECT YOUR CRUCIAL PERSONAL DETAILS.</h2>
                            <p>The information that we have published on our website – www.farescanner.uk are correct to the best of our knowledge. For the published details, we depend on our travel suppliers and their official websites to make sure that the information is correct and current. However, due to the dynamic nature of the travel industry and market complexity, the fares are subject to change and we may further implement these changes in the uploaded data without informing in advance.</p>
                            <h3>Website Disclaimer</h3>
                            <p>In case there is a harm, injury or loss that occurs because you relied on the information uploaded on our website, we cannot be held responsible for the same. This applies to all the links on our website and if in case you re-route to a third-party website from our site.</p>
                            <h3>Discount Policy</h3>
                            <p>Any discount provisions or offers that run on Farescanner changes according to the market determinants and policies. This includes the date of booking, the time of travel, the blackout dates or any pre-defined notices. When you claim discounts or savings on the tickets or services purchased with us, the fares may change without any prior information. Whenever you claim discounts and savings with us, we do a proper due diligence and assessment before confirming the offered fare.</p>
                            <h3>What's meant by 'the same itinerary'?</h3>
                            <p>When we say 'the same itinerary', it means that all the elements of the itinerary should match with our offered itinerary. This includes flight number, airline carrier, date of departure and return, the type of seat and the number of travelers.</p>
                            <h3>What's meant by 'offered and available for booking'?</h3>
                            <p>When we say 'offered and available for booking', we mean that the same fare is displayed on one of the major competitor OTAs and is still available to book. When our customer service agents verify the price match policy, the same will be checked by our service providers.</p>
                            <h3>PROMO CODES</h3>
                            <p>We at farescanner may offer promo codes that are applicable for online travel bookings. However, some specific codes are call-only and can be redeemed by reaching out to our customer helpline Centre.</p>
                            <p>To receive updates regarding the offers and promo codes we encourage all our users to sign up to our newsletters and receive the codes on their emails. The promo codes that we run on farescanner are not eligible for transfers, selling or barter and they have no cash value.</p>
                            <p>You are required to enter valid promo code in the link on the payment page to get the discounted amount. If you fail to enter the code, you cannot redeem any discount.</p>
                            <p>We have the right to revise or withdraw the promo code offers at any time without any prior notice.</p>
                            <p>We do not offer any recourse for technical errors leading to non-applicability of promo codes, except for your right to desist from going ahead with the purchase. In case we withdraw the offer, the promo code will become invalid and the website won’t accept any promo code entered while making the payment.</p>
                            <p>The displayed price (with/without promo code) is the billing amount. Under no circumstances will there be any credits or discounts that are applicable post the purchase.</p>
                            <p>We have the right to reject the transaction with an error in promo code even after you have completed the booking and issuance receipt has been emailed.</p>
                            <p>Please note that all HTML mark up codes, images and logos contained on this website, are owned by www. farescanner.uk and are protected by copyright laws, in case you find any content on our website which violates your privacy or any copyright laws then report us on info@farescanner.uk or call us on</p>
                            <p><strong><CallNo /></strong>, if we find your claim legitimate then we will remove the reported content immediately.</p> */}
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Disclaimer
