// DestinationDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function DestinationDetails() {
  const [destination, setDestination] = useState(null);
  const [error, setError] = useState(null);
  const { destinationName } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modifiedDestinationName = destinationName.replace(/\s+/g, '-');
        console.log('Modified Destination Name:', modifiedDestinationName);
        const response = await axios.get(`https://www.farescanner.uk/admin/DestinationAPI?name=${encodeURIComponent(modifiedDestinationName)}`);
        console.log('Response Data:', response.data);
        setDestination(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    fetchData();
  }, [destinationName]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!destination) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{destination.destinationsName}</h2>
      <p>Description: {destination.aboutDestinations}</p>
      {/* Add more details as needed */}
    </div>
  );
}

export default DestinationDetails;
