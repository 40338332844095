import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Include/Header';
import Footer from './Include/Footer';
import ScrollTop from './Include/ScrollTop';
import TermsAndConditions from './Company/TermsAndConditions';
import PrivacyPolicy from './Company/PrivacyPolicy';
import Disclaimer from './Company/Disclaimer';
import AboutUs from './Company/AboutUs';
import ContactUs from './Company/ContactUs';
import Home from './Company/Home';
import Airlines from './Airline/Airlines';
import Destination from './Destinations/Destination';
import DestinationsList from './Destinations/Destinationlist'; 
import DestinationDetails from './Destinations/DestinationDetails';
import Holidays from './Holidays/Holidays';
import CarHire from './CarHire/CarHire';
import Cruises from './Cruises/Cruises';
import CruisesDetails from './Cruises/CruisesDetails';
import Thanks from './Include/Thanks';

function App() {
  return (
    <Router>
      <ScrollTop />
      <Header />
      <Routes>
        <Route exact path='/Terms-and-Conditions' element={<TermsAndConditions />} />
        <Route exact path='/Privacy-Policy' element={<PrivacyPolicy />} />
        <Route exact path='/Disclaimer' element={<Disclaimer />} />
        <Route exact path='/About-Us' element={<AboutUs />} />
        <Route exact path='/Contact-Us' element={<ContactUs />} />
        <Route exact path='/Car-Hire' element={<CarHire />} />
        <Route exact path='/Cruises' element={<Cruises />} />        
        <Route path="/Cruise/:destination" element={<CruisesDetails />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/Airlines' element={<Airlines />} />
        <Route exact path='/Destination' element={<Destination />} />
        <Route exact path='/DestinationsList' element={<DestinationsList />} />
        <Route path="/Destination/:destination" element={<DestinationDetails />} />
        <Route exact path='/Holidays' element={<Holidays />} />
        <Route exact path='/Thanks' element={<Thanks />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
