import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import HolidayBanner from "../images/holiday/HolidayBanner.jpg";
import HolidaySlider1 from "../images/holiday/HolidaySlider1.jpg";
import HolidaySlider2 from "../images/holiday/HolidaySlider2.jpg";
import IndiaHoliday from "../images/holiday/IndiaHoliday.png";
import IslamabadHolidays from "../images/holiday/IslamabadHolidays.png";
import KenyaHoliday from "../images/holiday/KenyaHoliday.png";
import MaldivesHolidays from "../images/holiday/MaldivesHolidays.jpg";
import NepalHoliday from "../images/holiday/NepalHoliday.png";
import SouthAfricaHoliday from "../images/holiday/SouthAfricaHoliday.png";
import SriLankaHoliday from "../images/holiday/SriLankaHoliday.png";
import ThailandHiloday from "../images/holiday/ThailandHiloday.png";
import UnitedArabHoliday from "../images/holiday/UnitedArabHoliday.png";
import USAHoliday from "../images/holiday/USAHoliday.png";
function Holidays() {
  return (
    <>
       <div className='container-fluid p0'>
                <div className='row'>
                    <div className='col-md-12 homeSearch'>
                        <Carousel>
                            <Carousel.Item>
                                <img src={HolidayBanner} alt="HolidayBanner" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={HolidaySlider1} alt="HolidaySlider1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={HolidaySlider2} alt="bannerSlider" />
                            </Carousel.Item>

                        </Carousel>
                       
                    </div>
                </div>
            </div>

       <section className='pagecontent'>
       <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12">
        <div className="section-heading">
          <h2>Top Visited Places</h2>
          <br />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={SriLankaHoliday} alt="bannerSlider" /> <span className="badge">Sri Lanka</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Sri Lanka Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={IndiaHoliday} alt="bannerSlider" /> <span className="badge">India</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">India Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={KenyaHoliday} alt="bannerSlider" /> <span className="badge">Kenya</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Kenya Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={NepalHoliday} alt="bannerSlider" /> <span className="badge">Nepal</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Nepal Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={SouthAfricaHoliday} alt="bannerSlider" /> <span className="badge">South Africa</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">South Africa Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img"> <img src={ThailandHiloday} alt="bannerSlider" /><span className="badge">Thailand</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Thailand Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={UnitedArabHoliday} alt="bannerSlider" /> <span className="badge">United Arab</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">United Arab Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={USAHoliday} alt="bannerSlider" /> <span className="badge">USA</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">USA Holidays</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={MaldivesHolidays} alt="bannerSlider" /> <span className="badge">Maldives</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Maldives Holidays</a></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
      </section> 
    </>
  )
}

export default Holidays
