import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import AF from '../images/Airline/AF.png';
import BA from '../images/Airline/BA.png';
import BR from '../images/Airline/BR.png';
import DL from '../images/Airline/DL.png';
import EY from '../images/Airline/EY.png';
import KL from '../images/Airline/KL.png';
import LX from '../images/Airline/LX.png';
import MH from '../images/Airline/MH.png';
import NZ from '../images/Airline/NZ.png';
import QR from '../images/Airline/QR.png';
import SQ from '../images/Airline/SQ.png';
import UA from '../images/Airline/UA.png';
import VS from '../images/Airline/VS.png'; 
import EK from '../images/Airline/EK.png';
import CX from '../images/Airline/CX.png';
const WhyBookAirline = () => {
  const logoImages = [AF, BA, BR, DL, EY, KL, LX, MH, NZ, QR, SQ, UA, VS, EK, CX];
  const logoUrl = ['/business-class/air-france', '/business-class/british-airways', '/business-class/eva-air', '/business-class/delta-airlines', '/business-class/etihad-airways', '/business-class/klm-airlines', '/business-class/swiss-airlines', '/business-class/malaysian-airlines', '/business-class/air-newzealand', '/business-class/qatar-airways', '/business-class/singapore-airlines', '/business-class/united-airlines', '/business-class/virgin-atlantic', '/business-class/emirates', '/business-class/cathay-pacific'];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className='container airlineOffer '>
    <div className='row'>
    <div className='col-md-12 heading2'><h2>Why Book With Us?</h2><p>Book cheap flights from over 100 of the World's leading Airlines...</p></div>
    <div className='col-md-12'>
    <div className="logo-slider-container">
      <Slider {...settings}>
        {logoImages.map((logo, index) => (
          <div className='airline-box'>
            <Link to={logoUrl[index]}>         
            <img src={logo} alt={`${index + 1}`} />            
            </Link>
          </div>
        ))}
      </Slider>
    </div>
    </div>
    </div>
    </div>
  );
};

export default WhyBookAirline;
