import React from 'react';
import NewJersey from "../images/destination/new-jersey.jpg";
import SanFrancisco from "../images/destination/san_francisco.jpg";
import Virginia from "../images/destination/Virginia.jpg";
import LosAngeles from "../images/destination/los_angeles.jpg";
function MostPopular() {
  return (
    <>
      <div className='mostPopular'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 sheading2'><h2>Prime Hottest Deals</h2><p>Save big and visit these dream destinations!
            </p></div>

          </div>
          <div className='row destination-cover'>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/MAD.jpg" alt="Madrid" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>San Francisco</h3>
                  <div className='primepriceb'>&pound; <span>399</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
                  

            <div className='col-md-3'>
              <div className="continents-box">
                <div className='continents-boxImg'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/LIS.jpg" alt="Lisbon" />
                  <div className='continentsRound'></div>
                </div>
                <div className="continent-info">
                  <div className='primedate'>7 Mar - 15 Jun</div>
                  <h3>Los Angeles</h3>
                  <div className='primeprice'>&pound; <span>338</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>

            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/BCN.jpg" alt="Barcelona" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Las Vegas</h3>
                  <div className='primepriceb'>&pound; <span>290</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/AMS.jpg" alt="Amsterdam" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Bali</h3>
                  <div className='primepriceb'>&pound; <span>519</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/AGP.jpg" alt="Malaga" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Melbourne</h3>
                  <div className='primepriceb'>&pound; <span>680</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/MIL.jpg" alt="Milan" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Orlando</h3>
                  <div className='primepriceb'>&pound; <span>262</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src={NewJersey} alt="New Jersey" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Bangkok</h3>
                  <div className='primepriceb'>&pound; <span>295</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="continents-boxb">
                <div className='continents-boxImgb'>
                  <img className="img-fluid" src="https://www.edreams.com/images/destinations/600x600/BUH.jpg" alt="Bucharest" />
                  <div className='continentsRoundb'></div>
                </div>
                <div className="continent-infob">
                  <div className='primedateb'>7 Mar - 15 Jun</div>
                  <h3>Cape Town</h3>
                  <div className='primepriceb'>&pound; <span>449</span> </div>
                  <p>Prime price per passenger</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MostPopular