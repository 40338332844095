import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import DestBanner from "../images/destination/DestBanner.jpg"; 
import Africa from "../images/destination/Africa.jpg";
import Canada from "../images/destination/Canada.jpg";
import India from "../images/destination/India.jpg";
import Pakistan from "../images/destination/Pakistan.jpg";
import SriLanka from "../images/destination/SriLanka.jpg";
import USA from "../images/destination/USA.jpg";
import CallNo from '../Include/CallNo';
import SearchEng from '../Include/SearchEng';
import Destinationlist from './Destinationlist';
export default function Destination() {
  const [show, setShow] = React.useState();
 
  return (
    <>
     <div className='container-fluid p0'>
                <div className='row'>
                    <div className='col-md-12 homeSearch'>
                        <Carousel>
                            <Carousel.Item>
                                <img src={DestBanner} alt="DestBanner" />
                            </Carousel.Item>
                         </Carousel>
                         <div className='container myflight'>
                            <SearchEng />

                        </div>
                    </div>
                </div>
            </div>

       <section className='pagecontent'>
       <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-12">
        <div className="section-heading">
          <h2>Flight Special Offers</h2>
          <br />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={India} alt="India" /> <span className="badge">India</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Flights to India</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={Pakistan} alt="Pakistan" /> <span className="badge">Pakistan</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Flights to Pakistan</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={Canada} alt="Canada" /> <span className="badge">Canada</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Flights to Canada</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={Africa} alt="Africa" /> <span className="badge">Africa</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Fights to Africa</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img">  <img src={USA} alt="USA" /> <span className="badge">USA</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Flights to USA</a></h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card-item destination-card">
          <div className="card-img"> <img src={SriLanka} alt="Sri Lanka" /><span className="badge">Sri Lanka</span> </div>
          <div className="card-body">
            <h3 className="card-title"><a href="">Flights to Sri Lanka</a></h3>
          </div>
        </div>
      </div>  
    
    </div>
  <Destinationlist /> 
 </div>
      </section> 
    
  
    </>
  )
} 
 