import React from 'react';

function Airlines() {
  return (
    <>
      <section className='pagecontent'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1>Airlines</h1>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Airlines
