import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FromAirport from './FromAirport';
import ToAirport from './ToAirport';

export default function CruisesSearchEng() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isContactValid, setIsContactValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    const isValidNumber = /^\d{10}$/.test(inputValue);
    setContactNumber(inputValue);
    setIsContactValid(isValidNumber);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);
    setEmail(inputValue);
    setIsEmailValid(isValidEmail);
  };

  return (
    <>
      <div className="col-md-12">
        <form action="https://www.farescanner.uk/connecting.php" method="post" autoComplete="off">
          <div className="row">
            <input type="hidden" name="xx_Sb" value="Cruises Search" />
            <div className="col-md-3">
              <div className="form-group">
                <label>Cruising to</label>
                <FromAirport />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Departing from</label>
                <ToAirport />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Leaving</label>
                <DatePicker
                  className="form-control"
                  name="DepartDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={new Date()}
                  showDisabledMonthNavigation
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat='dd/MM/yyyy'
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Dropping off</label>
                <DatePicker
                  className="form-control"
                  name="ReturnDate"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat='dd/MM/yyyy'
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>Your Name</label>
                <input type="text" name="fname" className="form-control" required />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group">
                <label>Contact Number</label>
                <span className="input-group-text">+44</span>
                <input
                  type="tel"
                  name="cNumber"
                  className={`form-control ${isContactValid ? '' : 'is-invalid'}`}
                  maxLength={10}
                  minLength={10}
                  value={contactNumber}
                  onChange={handleContactNumberChange}
                  required
                />
                {!isContactValid && (
                  <div className="invalid-feedback">
                    Please enter a valid number
                  </div>
                )}
              </div>
              <div className="offertext">
           Please enter a valid number to get upto  £100 discount for this search.
          </div>
            </div>
            <div className="col-md-3">
              <div className="input-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className={`form-control ${isEmailValid ? '' : 'is-invalid'}`}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {!isEmailValid && (
                  <div className="invalid-feedback">
                    Please enter a valid email address for coupon code
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <button type='submit' name="CruisesSearch" className="btn btn-primary flightBTN">
                <i className="fa fa-ship"></i> Search Cruises
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
