import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CallNo from '../Include/CallNo';
function CruisesDetails() {
  const { destination } = useParams();
  const [cruises, setCruises] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCruiseDetails = async () => {
      try {
        if (!destination) {
          throw new Error('Destination parameter is undefined');
        }
        console.log('Destination:', destination); // Check if destination is defined
        const response = await fetch('https://www.farescanner.uk/admin/CruisesAPI');
        if (!response.ok) {
          throw new Error('Failed to fetch cruises data');
        }
        const data = await response.json();
        console.log('Data:', data); // Check if data is retrieved
        // Filter cruises with the matching destination
        const filteredCruises = data.filter(cruise => cruise.destinations.toLowerCase() === destination.toLowerCase());
        console.log('Filtered cruises:', filteredCruises); // Check if filtered cruises are found
        if (filteredCruises.length === 0) {
          throw new Error('No cruises found for the destination');
        }
        setCruises(filteredCruises);
      } catch (error) {
        console.error('Error fetching cruise details:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchCruiseDetails();
  }, [destination]);

  if (loading) {
    return <div className='textc'>Loading...</div>;
  }

  if (cruises.length === 0) {
    return <div className='textc'>No cruises found for the destination</div>;
  }

  return (
    <section className='crd'>
      <div className='container'>

        <div className='sheading2 crheading'><h2>Cruise Search For {destination}</h2></div>
        {cruises.map((cruise, index) => (
          <div className='row m25' key={index}>
            <div className='col-md-7 pl15'>
              <div className='crimgbox'>
                <div className='offertag'><span>75% OFF 2ND GUEST</span><span>UP TO $200 SAVINGS BONUS</span> <span>NEW SHIP</span></div>
                <img src={cruise.portImg} alt={cruise.port} />
              </div>
              <div className='crimgdetails'>

                <div className='night'>{cruise.Night} Nights</div>
                <div className='portName'>{cruise.port}</div>
              </div>

            </div>
            <div className='col-md-5'>
              <div className='shipCompany'><i className='fa fa-ship'></i>{cruise.ShipCompany}</div>
              <div className='triptype'><span>{cruise.TripType} FROM: </span>{cruise.TripLocation}</div>
              <div className='visiting'><strong>VISITING:</strong> {cruise.Visiting}</div>
              <hr />
              <div className='row'>
                <div className='col-md-7'>

                  <div className='crpp'> <p>AVG PER PERSON*</p><sup>£</sup>{cruise.price}</div>
                </div>
                <div className='col-md-5 crdet'>
                  <Link to="">Call Back Request</Link>
                  <CallNo />

                </div>
              </div>

            </div>


          </div>
        ))}
      </div>
    </section>
  );
}

export default CruisesDetails;
