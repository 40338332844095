import React from 'react';
import { Link } from 'react-router-dom';
import CallNo from './CallNo';
import footervery from "../images/img/footerOpp.png";
function Footer() {
    const today = new Date();
  const year = today.getFullYear();
    return (
        <>
            <div className='container ftCard'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src={footervery} alt="Card" />
                    </div>
                </div>
            </div>
            <footer>
                <div className='main-footer__bg'>
                    <div className='container-fluid'>
                        <div className='row'>
                        <div className='col-md-12'> <h3>Popular Fare Scanner UK Destinations</h3></div>
                            <div className='col-md-2'>
                                <ul>
                                <li> <Link to='/Abu-Dhabi'>Abu Dhabi Flights</Link></li>
                                <li> <Link to='/'>Adelaide Flights</Link></li>
                                <li> <Link to='/'>Auckland Flights</Link></li>
                                <li> <Link to='/'>Atlanta Flights</Link></li>
                                <li> <Link to='/'>Bali Flights</Link></li>
                                <li> <Link to='/'>Bangkok Flights</Link></li>
                                <li> <Link to='/'>Boston Flights</Link></li>
                                <li> <Link to='/'>Brisbane Flights</Link></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                                 <ul>
                                    <li> <Link to='/'>Cape Town Flights</Link></li>
                                    <li> <Link to='/'>Chicago Flights</Link></li>
                                    <li> <Link to='/'>Cheap Business Class Flights</Link></li>
                                    <li> <Link to='/'>Cheap First Class Flights</Link></li>
                                    <li> <Link to='/'>Durban Flights</Link></li>
                                    <li> <Link to='/'>Denver Flights</Link></li>
                                    <li> <Link to='/'>Detroit Flights</Link></li>
                                    <li> <Link to='/'>Dubai Flights</Link></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                            <ul>
                                    <li> <Link to='/'>Fort Lauderdale Flights</Link></li>
                                    <li> <Link to='/'>Hyderabad Flights</Link></li>
                                    <li> <Link to='/'>Hong Kong Flights</Link></li>
                                    <li> <Link to='/'>Johannesburg Flights</Link></li>
                                    <li> <Link to='/'>Las Vegas Flights</Link></li>
                                    <li> <Link to='/'>Lima Flights</Link></li>
                                    <li> <Link to='/'>Los Angeles Flights</Link></li>
                                    <li> <Link to='/'>Manila Flights</Link></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                            <ul>
                                    <li> <Link to='/'>Melbourne Flights</Link></li>
                                    <li> <Link to='/'>Miami Flights</Link></li>
                                    <li> <Link to='/'>Nairobi Flights</Link></li>
                                    <li> <Link to='/'>New York Flights</Link></li>
                                    <li> <Link to='/'>Orlando Flights</Link></li>
                                    <li> <Link to='/'>Perth Flights</Link></li>
                                    <li> <Link to='/'>Phuket Flights</Link></li>
                                    <li> <Link to='/'>Rio De Janeiro Flights</Link></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                            <ul>
                                    <li> <Link to='/'>San Francisco Flights</Link></li>
                                    <li> <Link to='/'>Sao Paulo Flights</Link></li>
                                    <li> <Link to='/'>Sydney Flights</Link></li>
                                    <li> <Link to='/'>Tampa Flights</Link></li>
                                    <li> <Link to='/'>Toronto Flights</Link></li>
                                    <li> <Link to='/'>Washington Flights</Link></li>
                                    <li> <Link to='/'> </Link></li>
                                    <li> <Link to='/'> </Link></li>
                                </ul>
                            </div>
                            <div className='col-md-2'>
                                <h3>Get In Touch</h3>
                                <ul>
                                    <li><i class="fa fa-map-marker"></i> 5 Brayford Square, London,<br /> England, E1 0SG</li>
                                    <li><i class="fa fa-phone-square"></i> <CallNo /></li>
                                    <li><i class="fa fa-envelope"></i> info@farescanner.uk</li>
                                    <li><i class="fa fa-clock-o"></i> Mon - Sat: 09:00 - 21:00<br />
                                        Sun: Closed</li>
                                </ul>                              

                            </div>
                            <div className='ftline'><hr /></div>

                            <div className='col-md-10 fareLink'>
                            <h3>Fare Scanner UK</h3>
                            <ul>
                                    <li> <Link to='/'>Home</Link></li>
                                    <li> <Link to='/About-Us'>About Us</Link></li>
                                    <li> <Link to='/Contact-Us'>Contact Us</Link></li>
                                    <li> <Link to='/Privacy-Policy'>Privacy Policy</Link></li>
                                    <li> <Link to='/Terms-and-Conditions'>Terms and Conditions</Link></li>
                                   <li> <Link to='/Disclaimer'>Disclaimer</Link></li>
                                    
                                </ul>
                            </div>
                            <div className='col-md-2'>
                            <h3>Connect with us</h3>
                                <ul className='sociallink'>
                                <li><Link to='https://www.facebook.com/profile.php?id=61555394912135' target='_blank'><i class="fa fa-facebook"></i></Link></li>
                                <li><Link to='https://www.instagram.com/farescanner_77/' target='_blank'><i class="fa fa-instagram"></i></Link></li>
                                <li><Link to='https://twitter.com/MalcolmBen71536' target='_blank'><i class="fa fa-linkedin"></i></Link></li>
                                <li><Link to='https://in.pinterest.com/farescanner77/' target='_blank'><i class="fa fa-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <div className='disclaimer'>
                <div className='container-fluid'>
                    <p>DISCLAIMER: Fare Scanner uk is an independent online travel service provider that provides cost-effective travel services. We do not claim that we are Airlines. Brands displayed on our pages are owned by the respective brand owners. In case if you find any content on our website which violates your privacy or any copyright laws then report us on info@farescanner.uk call us <i class="fa fa-phone-square"></i> <CallNo />.</p>
                </div>
            </div>
            <div className='copyright'><p>Copyright © {year} Fare Scanner Ltd. all rights reserved.</p></div>
        </>
    )
}

export default Footer