// DestinationsList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function DestinationsList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.farescanner.uk/admin/DestinationAPI');
        setData(response.data); // Assuming the response is an array of destinations directly
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='container p0'>
      <div className='row'>
        <div className='sheading2 crheading'><h2>Top Destinations</h2></div>
        </div>
        <div className='row'>
         {data.map(destination => (
             <div className="col-lg-3 topdes" key={destination.id}>
              <ul>
              <Link to={`/Destination/${encodeURIComponent(destination.destinationsName.replace(/\s+/g, '-'))}`}>
              <li>
               {destination.destinationsName}              
              </li>
              </Link>
              </ul>
              </div>
          ))}
        
        </div>
      </div>     
  );
}

export default DestinationsList;
