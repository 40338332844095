import React from 'react';
import CallNo from '../Include/CallNo';
function ContactUs() {
    return (
        <>
            <section className='pagecontent'>
                <div className="container">
                    <div className="contact__wrapper mt-n9">
                        <div className="row no-gutters">
                        <div className="col-lg-12"><h1>Contact Us</h1></div>
                            <div className="col-lg-5 contact-info__wrapper gradient-brand-color p-3 order-lg-2">
                                <h2 className="color--white mb-5">Get In Touch</h2>
                                <ul className="contact-info__list list-style--none position-relative z-index-101">
                                    <li className="mb-4 pl-4"> <span className="position-absolute"><i className="fa fa-envelope"></i></span> info@farescanner.uk</li>
                                    <li className="mb-4 pl-4"> <span className="position-absolute"><i className="fa fa-phone"></i></span> <CallNo /> </li>
                                    <li className="mb-4 pl-4"> <span className="position-absolute">
                                        <i class="fa fa-map-marker"></i></span> 5 Brayford Square, London,<br /> England, E1 0SG

                                    </li>
                                    <li className="mb-4 pl-4"> <span className="position-absolute">
                                        <i class="fa fa-clock-o"></i></span> Mon - Sat: 09:00 - 21:00<br />
                                        Sun: Closed

                                    </li>
                                </ul>

                            </div>
                            <div className="col-lg-7 contact-form__wrapper p-3 order-lg-1">
                             <form action="#" className="contact-form form-validate" novalidate="novalidate">
                                    <div className="row">
                                        <div className="col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label className="required-field" for="firstName">First Name</label>
                                                <input type="text" className="form-control" id="firstName" name="firstName" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label for="lastName">Last Name</label>
                                                <input type="text" className="form-control" id="lastName" name="lastName" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label className="required-field" for="email">Email</label>
                                                <input type="text" className="form-control" id="email" name="email" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label for="phone">Contact Number</label>
                                                <input type="tel" className="form-control" id="phone" name="phone" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label className="required-field" for="message">Message</label>
                                                <textarea className="form-control" id="message" name="message" rows="4"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <button type="submit" name="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ContactUs
