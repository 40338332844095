import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Whytrust from '../Include/Whytrust';
import cruisesSlider1 from "../images/cruises/cruisesSlider1.jpg";
import cruisesSlider2 from "../images/cruises/cruisesSlider2.jpg";
import cruisesSlider3 from "../images/cruises/cruisesSlider3.jpg";
import CruisesSearchEng from '../Include/CruisesSearchEng';
import Cruiseslist from './Cruiseslist';


function Cruises() {
    return (
        <>
            <div className='container-fluid p0'>
                <div className='row'>
                    <div className='col-md-12 homeSearch'>
                        <Carousel>
                            <Carousel.Item>
                                <img src={cruisesSlider1} alt="cruisesSlider1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={cruisesSlider2} alt="cruisesSlider2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={cruisesSlider3} alt="cruisesSlider3" />
                            </Carousel.Item>

                        </Carousel>
                        <div className='container myflight'>
                            <CruisesSearchEng />

                        </div>
                    </div>
                </div>
            </div>
            <Cruiseslist />
            <Whytrust />         

        </>
    )
}

export default Cruises
