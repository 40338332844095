import React from 'react';
import NewJersey from "../images/destination/new-jersey.jpg";
import Delaware from "../images/destination/Delaware.jpg";
import LosAngeles from "../images/destination/los_angeles.jpg";
import Nevada from "../images/destination/Nevada.jpg";
import NewYorkCity from "../images/destination/NewYorkCity.jpg";
import NorthAmerica from "../images/destination/NewJersey.jpg";
import SanFrancisco from "../images/destination/san_francisco.jpg";
import SouthAfrica from "../images/destination/Delaware.jpg";
import UnitedStates from "../images/destination/Wilmington.jpg";
import Virginia from "../images/destination/Virginia.jpg";
import Wilmington from "../images/destination/Wilmington.jpg";
import California from "../images/destination/California.jpg";
function FlightsAround() {
    return (
        <>
            <div className='newdesti'>
                <div className='container newdes'>
                    <div className='row'>
                        <div className='col-md-12 newdestih'><h2>Flights Around the World</h2><p>Search for the cheapest flights to any airport anywhere in the world and we'll help you find the best deals available.</p></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={Wilmington} alt="Puerto Rico" />
                                </div>
                                <div className='desDetails'><h4>Puerto Rico<span>&pound; 379</span></h4>
                     <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={NewJersey} alt="Cancun" />
                                </div>
                                <div className='desDetails'><h4>Cancun <span>&pound; 389</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={Delaware} alt="Perth" />
                                </div>
                                <div className='desDetails'><h4>Perth <span>&pound; 485</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={LosAngeles } alt="Christchurch" />
                                </div>
                                <div className='desDetails'><h4>Christchurch  <span>&pound; 849</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={California} alt="Auckland" />
                                </div>
                                <div className='desDetails'><h4>Auckland <span>&pound; 625</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={Nevada} alt="Sydney" />
                                </div>
                                <div className='desDetails'><h4>Sydney<span>&pound; 560</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={NewYorkCity} alt="Miami" />
                                </div>
                                <div className='desDetails'><h4>Miami <span>&pound; 265</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={NorthAmerica} alt="Dubai" />
                                </div>
                                <div className='desDetails'><h4>Dubai <span>&pound; 265</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={SanFrancisco} alt="Boston" />
                                </div>
                                <div className='desDetails'><h4>Boston <span>&pound; 303</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={SouthAfrica} alt="Vancouver" />
                                </div>
                                <div className='desDetails'><h4>Vancouver <span>&pound; 299</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={UnitedStates} alt="Toronto" />
                                </div>
                                <div className='desDetails'><h4>Toronto <span>&pound; 280</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='desdet'>
                                <div className='desimg'>
                                    <img src={Virginia} alt="Bangkok" />
                                </div>
                                <div className='desDetails'><h4>Bangkok <span>&pound; 295</span></h4>
                                <a href='tel:0208 095 4111'><i className='fa fa-phone'> </i> Call to Book </a> 
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default FlightsAround