import React, { useState } from 'react';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import FromAirport from './FromAirport';
import ToAirport from './ToAirport'; 
export default function SearchEng() {
  const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  const [contactNumber, setContactNumber] = useState('');
      const [isValid, setIsValid] = useState(true);
    
      const handleContactNumberChange = (e) => {
        const inputValue = e.target.value;
        // Use a regular expression to check if the input consists of exactly 10 digits
        const isValidNumber = /^\d{10}$/.test(inputValue);
        
        setContactNumber(inputValue);
        setIsValid(isValidNumber);
      };
  return (
    <>
     <div className="col-md-12">
     <form action="https://www.farescanner.uk/connecting.php" method="post" autocomplete="off">
    <div className="row">
    <input type="hidden" name="xx_Sb" value="Search Flight Farescanner.uk" />
    <div className="col-md-3">
        <div className="form-group">
          <label>Flying From</label>
          <FromAirport />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label>Flying To</label>
          <ToAirport />
         
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label>Departing</label>         
          <DatePicker className="form-control" name="DepartDate"
               selected={startDate}
               onChange={(date) => setStartDate(date)}
               minDate={new Date()}
             
               showDisabledMonthNavigation
               selectsStart
               startDate={startDate}
               endDate={endDate}
                dateFormat='dd/MM/yyyy'
                required
            />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label>Returning</label>          
          <DatePicker className="form-control" name="ReturnDate"
                 selected={endDate}
                 onChange={(date) => setEndDate(date)}
                 selectsEnd
                 startDate={startDate}
                 endDate={endDate}
                 minDate={startDate}                
                dateFormat='dd/MM/yyyy' 
                required
            />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label>Your Name </label>
          <input type="text" name="fname" className="form-control" required />
        </div>
      </div>
      <div className="col-md-3">
      <div className="input-group">
        <label>Contact Number</label>
        <span className="input-group-text">+44</span>
        <input
          type="number"
          name="cNumber"
          className={`form-control ${isValid ? '' : 'is-invalid'}`}
          maxLength={10}
          minLength={10}
          value={contactNumber}
          onChange={handleContactNumberChange}
          required
        />
        {!isValid && (
          <div className="invalid-feedback">
           Please enter a valid number
          </div>
        )}
      </div>
      <div className="offertext">
           Please enter a valid number to get upto  £100 discount for this search.
          </div>
    </div>
      <div className="col-md-2 w30">
        <div className="form-group">
          <label>Adults</label>
          <select name="Adults" id="Adults" className="form-control">
            <option value="1" selected="selected">1 Adult</option>
            <option value="2">2 Adults</option>
            <option value="3">3 Adults</option>
            <option value="4">4 Adults</option>
            <option value="5">5 Adults</option>
            <option value="6">6 Adults</option>
            <option value="7">7 Adults</option>
            <option value="8">8 Adults</option>
            <option value="9">9 Adults</option>
          </select>
        </div>
      </div>
      <div className="col-md-1 w30">
        <div className="form-group">
          <label>Children </label>
          <select name="Children" id="Children" className="form-control">
            <option value="0" selected="selected">0 Child</option>
            <option value="1">1 Child</option>
            <option value="2">2 Children</option>
            <option value="3">3 Children</option>
            <option value="4">4 Children</option>
            <option value="5">5 Children</option>
            <option value="6">6 Children</option>
            <option value="7">7 Children</option>
            <option value="8">8 Children</option>
            <option value="9">9 Children</option>
          </select>
        </div>
      </div>
      <div className="col-md-1 w30">
        <div className="form-group">
          <label>Infant </label>
          <select name="Infants" id="Infants" className="form-control">
            <option value="0" selected="selected">0 Infant</option>
            <option value="1">1 Infant</option>
            <option value="2">2 Infant</option>
            <option value="3">3 Infant</option>
            <option value="4">4 Infant</option>
            <option value="5">5 Infant</option>
            <option value="6">6 Infant</option>
            <option value="7">7 Infant</option>
            <option value="8">8 Infant</option>
            <option value="9">9 Infant</option>
          </select>
        </div>
      </div>
      
      <div className="col-md-2">
      <button type='submit'  name="SearchFlight" className="btn btn-primary flightBTN"><i className="fa fa-plane"></i> Get Best Prices</button>
       
      </div>
    </div>
  </form>
</div> 
    </>
  )
}

 
