import React from 'react';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Carimg from "../images/carhire/carimg.jpg";
function Carlist() {
    const [key, setKey] = useState('London');

    return (
        <>
        <div className='container p0'>
            <div className='row carsec'>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="London" title="London">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='carlist'>
                                <p>From <span>£13.33</span> /day £93.31 • 7 days</p>
                                <img src={Carimg} alt="car" />
                                <h2>Compact Car</h2>
                                <hr />
                                <h1>London</h1>
                                <h6>United Kingdom </h6>
                                <a href='#'>View Deal</a>
                            </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='carlist'>
                                <p>From <span>£13.33</span> /day £93.31 • 7 days</p>
                                <img src={Carimg} alt="car" />
                                <h2>Compact Car</h2>
                                <hr />
                                <h1>London</h1>
                                <h6>United Kingdom </h6>
                                <a href='#'>View Deal</a>
                            </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='carlist'>
                                <p>From <span>£13.33</span> /day £93.31 • 7 days</p>
                                <img src={Carimg} alt="car" />
                                <h2>Compact Car</h2>
                                <hr />
                                <h1>London</h1>
                                <h6>United Kingdom </h6>
                                <a href='#'>View Deal</a>
                            </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='carlist'>
                                <p>From <span>£13.33</span> /day £93.31 • 7 days</p>
                                <img src={Carimg} alt="car" />
                                <h2>Compact Car</h2>
                                <hr />
                                <h1>London</h1>
                                <h6>United Kingdom </h6>
                                <a href='#'>View Deal</a>
                            </div>
                            </div>
                            
                        </div>
                    </Tab>
                    <Tab eventKey="Orlando" title="Orlando">
                        Coming Soon...!
                    </Tab>
                    <Tab eventKey="Alicante" title="Alicante">
                    Coming Soon...!
                    </Tab>
                </Tabs>

            </div>
        </div>
        </>
    );
}

export default Carlist;